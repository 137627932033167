export default function getAbsoluteOffset(element) {
  const rect = element.getBoundingClientRect()

  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
    right: rect.right + window.scrollX,
    bottom: rect.bottom + window.scrollY,
    height: rect.height,
    width: rect.width,
  }
}
