import React from 'react'
import useResizeObserver from '@react-hook/resize-observer'

function useElementSize(ref) {
  const [size, setSize] = React.useState<any>()

  React.useLayoutEffect(() => {
    setSize(ref.current.getBoundingClientRect())
  }, [ref])

  // Where the magic happens
  useResizeObserver(ref, entry => setSize(entry.contentRect))
  return size
}

export default useElementSize
