import getAbsoluteOffset from '../utils/getAbsoluteOffset'
import useWindowSize from './useWindowSize'

function useHeaderLines() {
  const [windowWidth] = useWindowSize()

  if (!windowWidth) {
    return []
  }

  const navRect = getAbsoluteOffset(document.querySelector('.nav'))
  const logoRect = getAbsoluteOffset(document.querySelector('.logo'))
  const mobileContainerRect = getAbsoluteOffset(
    document.querySelector('.header > .container:nth-child(2)'),
  )

  if (windowWidth > 768) {
    return [
      logoRect.right + 5,
      logoRect.top + logoRect.height / 2,

      navRect.right,
      logoRect.top + logoRect.height / 2,

      navRect.right,
      navRect.bottom,
    ]
  } else {
    return [
      mobileContainerRect.left,
      mobileContainerRect.top,

      mobileContainerRect.right,
      mobileContainerRect.top,

      mobileContainerRect.right,
      mobileContainerRect.bottom,
    ]
  }
}

export default useHeaderLines
